import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

type Props = {
    asModal?: boolean;
    asAgreement?: boolean;
    defaultShow?: boolean;
};

export default function Disclaimer(props: Props) {
    const [showConfirm, setShowConfirm] = useState(props.asAgreement ?? false);
    const [isAgree, setIsAgree] = useState(false);
    const renderDisclaimerContent = () => {
        return (
            <div>
                <h2>Disclaimer</h2>
                <ul>
                    <li>
                        Setiap peserta <b>WAJIB</b> melakukan pendaftaran dan
                        memenuhi semua prasyarat registrasi untuk mengikuti
                        Retreat Senior.
                    </li>
                    <li>
                        Setiap peserta <b>WAJIB</b> memiliki akun pribadi pada
                        GMS App dan sudah mengisi data diri secara LENGKAP.
                    </li>
                    <li>
                        Peserta yang melebihi batas waktu 3x24 jam akan terhapus
                        secara otomatis dan <b>WAJIB</b> mendaftar ulang pada
                        website selama kuota masih tersedia.
                    </li>
                    <li>
                        Bagi Peserta on-site yang sudah dinyatakan
                        ter-registrasi / pembayaran terkonfirmasi akan menerima
                        QR Code untuk pengambilan tiket gelang dan akses masuk
                        untuk setiap sesi.
                    </li>
                    <li>
                        Untuk peserta yang tidak dapat hadir dan atau
                        mengundurkan diri <b>TIDAK</b> dapat mengajukan refund.
                    </li>
                </ul>
            </div>
        );
    };
    if (props.asModal || props.asAgreement) {
        return (
            <>
                <Modal
                    show={showConfirm}
                    onHide={() => setShowConfirm(false)}
                    keyboard={false}
                    backdrop={props.asAgreement ? "static" : undefined}
                >
                    <Modal.Body>{renderDisclaimerContent()}</Modal.Body>
                    <Modal.Footer>
                        {!props.asAgreement && (
                            <Button
                                variant="secondary"
                                onClick={() => setShowConfirm(false)}
                            >
                                Tutup
                            </Button>
                        )}
                        {!!props.asAgreement && (
                            <>
                                <Form.Check
                                    id="checkAgree"
                                    type="checkbox"
                                    defaultChecked={false}
                                    onChange={(e) =>
                                        setIsAgree(e.target.checked)
                                    }
                                    label="Saya telah membaca dan menyetujui semua syarat dan ketentuan
                  yang berlaku."
                                />
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        isAgree && setShowConfirm(false)
                                    }
                                >
                                    Submit
                                </Button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
            </>
        );
    } else {
        return <>{renderDisclaimerContent()}</>;
    }
}
