import { useEffect, useState } from "react";
import { getApiInstance, isOperatorAuthenticated, isUserAuthenticated } from "./helper";
import * as Sentry from "@sentry/react";
import Swal from "sweetalert2";
import { Item } from "../interfaces/interfaces";

type Props = {
    isOperator?: boolean;
}

export default function useAvailableItems(props: Props) {
    const [items, setItems] = useState([] as Item[]);
    const getData = async () => {
        const instance = getApiInstance();
        try {
            const rawResponse = await instance.get("items");
            const data: Item[] = rawResponse.data.result;
            setItems(data)
        } catch (error: any) {
            Sentry.captureException(error);
            if (error.response) {
                Swal.fire("Error", error.response.data.error, "error");
            } else {
                Swal.fire("Error", "Terjadi kesalahan!", "error");
            }
        }
    }
    useEffect(() => {
        if (props.isOperator) {
            if (isOperatorAuthenticated()) {
                getData()
            }
        } else {
            if (isUserAuthenticated()) {
                getData()
            }
        }
    }, [props.isOperator])
    return { items };
}
