import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { ApiAuthResponse, ApiResponse } from "../interfaces/interfaces";

export const ICT_ADMIN = '2cf2e00fc14d34ffefe7875f30041c7f';

export function isOperatorAuthenticated(): boolean {
    if (sessionStorage.getItem('operator_token') != null) {
        return true;
    } else {
        return false;
    }
}

export function isUserAuthenticated(): boolean {
    if (sessionStorage.getItem('auth_code') != null) {
        return true;
    } else {
        return false;
    }
}

export function isAuthenticated(): boolean {
    if (sessionStorage.getItem('admin_token')) {
        return true;
    } else {
        return false;
    }
}

export function getApiInstance(): AxiosInstance {
    const payload = {
        baseURL: process.env.REACT_APP_ACCOUNT_API_URL
    } as AxiosRequestConfig<any>;
    const token = sessionStorage.getItem('auth_code');
    if (token) { // jika ada token di session
        payload.headers = {
            authorization: 'Bearer ' + token
        }
    }
    const instance = axios.create(payload);
    return instance;
}

export function getAdminApiInstance(): AxiosInstance {
    const payload = {
        baseURL: process.env.REACT_APP_ACCOUNT_API_URL
    } as AxiosRequestConfig<any>;
    const token = sessionStorage.getItem('admin_token');
    if (token) { // jika ada token di session
        payload.headers = {
            authorization: 'Bearer ' + token
        }
    }
    const instance = axios.create(payload);
    return instance;
}

export function getOperatorApiInstance(): AxiosInstance {
    const payload = {
        baseURL: process.env.REACT_APP_ACCOUNT_API_URL
    } as AxiosRequestConfig<any>;
    const token = sessionStorage.getItem('operator_token');
    if (token) { // jika ada token di session
        payload.headers = {
            authorization: 'Bearer ' + token
        }
    }
    const instance = axios.create(payload);
    return instance;
}

export async function login(authCode: string) {
    const instance = getApiInstance();
    try {
        const formData = new FormData();
        formData.set("auth_code", authCode);
        const rawResponse = await instance.post('admin/auth', formData);
        const response: ApiResponse<ApiAuthResponse> = rawResponse.data;
        sessionStorage.setItem('admin_token', response.token!);
        localStorage.setItem('person_id', response.result.mygms_id);
        return true;
    } catch (err) {
        console.error("Login failed");
        console.error(err);
        return false;
    }
}

export function getUserId() {
    return localStorage.getItem("person_id")
}

export async function operatorLogin(authCode: string) {
    const instance = getApiInstance();
    try {
        const formData = new FormData();
        formData.set("auth_code", authCode);
        const rawResponse = await instance.post('operator/auth', formData);
        const response: ApiResponse<ApiAuthResponse> = rawResponse.data;
        sessionStorage.setItem('operator_token', response.token!);
        localStorage.setItem('person_id', response.result.mygms_id);
        return true;
    } catch (err) {
        console.error("Login failed");
        console.error(err);
        return false;
    }
}

export async function logout() {
    sessionStorage.removeItem('admin_token');
    localStorage.removeItem('person_id');
    window.location.href = '/';
}