import React from "react";
import QRCode from "react-qr-code";

type Props = {
    payment_qr: string;
    bill_no: string;
}

export default function CashHowTo(props: Props) {
    return <div>
        Silahkan tunjukkan QR Code di bawah ke operator yang bertugas
        <div className="text-center">
            <div className="qrcode-wrapper">
                <QRCode value={props.payment_qr} size={128}></QRCode>
            </div>
            <div>
                {props.bill_no}
            </div>
        </div>
    </div>
}