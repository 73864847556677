import "./App.scss";
import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import RegisterPage from "./pages/RegisterPage";
import {
  isAuthenticated,
  isOperatorAuthenticated,
  login,
  logout,
  operatorLogin,
} from "./utils/helper";
import Swal from "sweetalert2";
const OperatorPage = React.lazy(() => import("./pages/OperatorPage"));
const OperatorToolkitPage = React.lazy(
  () => import("./pages/OperatorToolkitPage")
);
const AdminPage = React.lazy(() => import("../src/pages/AdminPage"));
const AdminRegistrantPage = React.lazy(
  () => import("../src/pages/AdminRegistrantPage")
);
function App() {
  const query = new URLSearchParams(useLocation().search);
  const authCode = query.get("auth_code")
    ? query.get("auth_code")
    : query.get("code");
  const pathname = window.location.pathname;
  // khusus url yang perlu login
  if (pathname === "/admin/logout") {
    logout();
    return <div>Logging you out...</div>;
  } else if (pathname.startsWith("/admin")) {
    const code = query.get("code");
    // permintaan untuk login ulang
    if (!code) {
      // khusus bila belum pernah login, dan tidak ada parameter code, diabaikan saja
      if (!isAuthenticated()) {
        const redirectUrl = process.env.REACT_APP_BASE_URL + "/admin";
        const clientId = "61df7b4dedd41afd950e06851d3e6d9b";
        let url = `https://accounts.gms.church/oauth/authorize?scope=profile&state=state&client_id=${clientId}&redirect_uri=${redirectUrl}`;
        window.location.href = url;
        return <div></div>;
      }
    } else {
      // bila ada code, tukarkan ke token admin
      login(code)
        .then((success: boolean) => {
          if (success) {
            window.location.href = "/admin"; // arahkan ulang ke halaman admin
          } else {
            Swal.fire(
              "Login Failed",
              "Login gagal! Silahkan periksa apakah Anda login menggunakan user yang benar.",
              "error"
            );
          }
        })
        .catch((exc) => {
          console.error(exc);
          Swal.fire("Error", "Something went wrong!", "error");
        });
      return <div></div>;
    }
  } else if (pathname.startsWith("/operator")) {
    if (!authCode) {
      // khusus bila belum pernah login, dan tidak ada parameter code, diabaikan saja
      if (!isOperatorAuthenticated()) {
        const redirectUrl = process.env.REACT_APP_BASE_URL + "/operator";
        const clientId = "61df7b4dedd41afd950e06851d3e6d9b";
        let url = `https://accounts.gms.church/oauth/authorize?scope=profile&state=state&client_id=${clientId}&redirect_uri=${redirectUrl}`;
        window.location.href = url;
        return <div></div>;
      }
    } else {
      // bila ada code, tukarkan ke token admin
      operatorLogin(authCode)
        .then((success: boolean) => {
          if (success) {
            window.location.href = "/operator"; // arahkan ulang ke halaman admin
          } else {
            Swal.fire(
              "Login Failed",
              "Login gagal! Silahkan periksa apakah Anda login menggunakan user yang benar.",
              "error"
            );
          }
        })
        .catch((exc) => {
          console.error(exc);
          Swal.fire("Error", "Something went wrong!", "error");
        });
      return <div></div>;
    }
  }
  if (authCode) {
    // simpan code untuk login
    sessionStorage.setItem("auth_code", authCode);
  }

  return (
    <Suspense fallback="loading">
      <Routes>
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/operator" element={<OperatorPage />} />
        <Route path="/operator/toolkit" element={<OperatorToolkitPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/registrants" element={<AdminRegistrantPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
