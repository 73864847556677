import "./register-page.scss";
import React, { useEffect, useState } from "react";
import { getApiInstance, isUserAuthenticated } from "../utils/helper";
import moment from "moment";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import useAvailableItems from "../utils/AvailableItems";
import Quota from "./partials/Quota";
import RegistrationStatusWidget from "./partials/RegistrationStatusWidget";
import { Transaction } from "../interfaces/interfaces";
import Disclaimer from "./partials/Disclaimer";
// import Howto from "./partials/Howto";
import * as yup from "yup";
import { useFormik } from "formik";
import { Guardian } from "./OperatorPage";
import QRCode from "react-qr-code";

type RegistrationDetail = {
    bill_no: string;
    guardians: Guardian[];
    with_guardian: boolean;
    ticket_value?: string;
};

export type RegistrationStatus = {
    registered: boolean;
    transaction: Transaction;
    registration_detail: RegistrationDetail;
};

type Registrant = {
    mygms_id: string;
    name: string;
    age?: number;
    birth_date: string;
    church_id: string;
    cell_phone: string;
    email: string;
    registration_status?: RegistrationStatus;
};

type RegisterForm = {
    address: string;
    guardian_total: number;
    guardians: Guardian[];
};

const registrationSchema = yup.object().shape({
    address: yup.string().max(300).required("Alamat wajib diisi"),
    guardian_total: yup.number().integer().min(0).max(2).required(),
    guardians: yup.array().when("guardian_total", {
        is: (val: any) => {
            return val > 0;
        },
        then: yup
            .array()
            .of(
                yup.object().shape({
                    name: yup
                        .string()
                        .trim()
                        .matches(/^[a-zA-Z0-9. ]+$/g, "Nama tidak valid")
                        .max(100, "Maksimal 100 karakter")
                        .required("Nama wajib diisi"),
                    phone_number: yup
                        .string()
                        .trim()
                        .matches(/^[0-9]+$/g, "Nomor HP tidak valid")
                        .min(10, "Minimal 10 digit")
                        .max(15, "Maksimal 15 digit")
                        .required("Nomor HP wajib diisi"),
                })
            )
            .max(2)
            .min(1)
            .required(),
    }),
});

function RegisterPage() {
    const [registrant, setRegistrant] = useState({} as Registrant);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTicket, setSelectedTicket] = useState("");
    // const [isUnderAge, setIsUnderAge] = useState(false);
    const { items } = useAvailableItems({
        isOperator: false,
    });

    useEffect(() => {
        if (items.length === 1) {
            setSelectedTicket(items[0].id);
        } else {
            for (let item of items) {
                if (
                    item.church_ids &&
                    item.church_ids.includes(registrant.church_id)
                ) {
                    setSelectedTicket(item.id);
                    break;
                }
            }
        }
    }, [items, registrant.church_id]);
    useEffect(() => {
        const getData = async () => {
            const instance = getApiInstance();
            const rawResponse = await instance.get("/me/profile");
            const data: Registrant = rawResponse.data.result;
            setRegistrant({
                mygms_id: data.mygms_id,
                name: data.name,
                age: data.age,
                church_id: data.church_id,
                birth_date: moment(data.birth_date).format("D MMM Y"),
                cell_phone: data.cell_phone,
                email: data.email,
                registration_status: data.registration_status,
            });
            //setIsUnderAge(data.age == null || data.age < 50);
            setIsLoading(false);
        };
        // ambil data bila sudah login
        if (isUserAuthenticated()) {
            getData();
        } else {
            doRelogin();
        }
    }, []);
    const doRelogin = () => {
        window.location.href = `https://accounts.gms.church/oauth/authorize?scope=profile&state=state&client_id=4751a125e78848887d937781b3f62d44&redirect_uri=${process.env.REACT_APP_BASE_URL}/register`;
    };

    const isRegistered = (regStatus?: RegistrationStatus) => {
        return regStatus?.registered && regStatus.transaction.status === "PAID";
    };

    const isNotRegistered = (regStatus?: RegistrationStatus) => {
        return (
            !regStatus?.registered ||
            regStatus.transaction.status === "EXPIRED" ||
            regStatus.transaction.status === "CANCELED"
        );
    };

    const handleRegister = async (values: RegisterForm) => {
        if (isLoading || !selectedTicket) return;
        values.guardian_total = +values.guardian_total;
        setIsLoading(true);
        const instance = getApiInstance();
        if (values.guardian_total > 0) {
            for (let guardian of values.guardians) {
                guardian.name = guardian.name.trim().toUpperCase();
                guardian.phone_number = guardian.phone_number.trim();
            }
        }
        try {
            const rawResponse = await instance.post(
                "/me/register?item_id=" + selectedTicket,
                values
            );
            // redirect ke halaman yang dituju
            window.location = rawResponse.data.redirect_url;
        } catch (error: any) {
            Sentry.captureException(error);
            if (error.response) {
                Swal.fire("Error", error.response.data.error, "error");
            } else {
                Swal.fire("Error", "Terjadi kesalahan!", "error");
            }
            setIsLoading(false);
        }
    };
    const formik = useFormik({
        initialValues: {
            guardian_total: 0,
            guardians: [] as Guardian[],
        } as RegisterForm,
        validationSchema: registrationSchema,
        onSubmit: handleRegister,
    });
    // handler upload bukti pembayaran
    const handleImageUpload = async (e: any) => {
        const file = e.target.files[0];
        const imageType = file.type;
        const supportedTypes = ["image/jpeg", "image/png"];
        if (!supportedTypes.includes(imageType)) {
            Swal.fire(
                "Format gambar tidak didukung",
                "Silahkan pilih file dengan format .jpg, .jpeg, atau .png.",
                "warning"
            );
            return;
        }
        Swal.fire({
            title: "Upload bukti pembayaran?",
            icon: "question",
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            showCancelButton: true,
            preConfirm: () => {
                var formData = new FormData();
                formData.append("file", file);
                formData.append(
                    "bill_no",
                    registrant.registration_status!.transaction.bill_no
                );
                const instance = getApiInstance();
                return instance
                    .post("me/payment/upload", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((result: any) => {
                        if (result.status === 200) {
                            // update data yang sudah terupload
                            const tempRegistrant = registrant;
                            registrant.registration_status!.transaction.payment_receipt_url =
                                result.data.image_url;
                            setRegistrant({ ...tempRegistrant });
                            Swal.fire({
                                title: "Bukti pembayaran berhasil di upload",
                                imageUrl: result.data.image_url,
                                icon: "success",
                            });
                        }
                    })
                    .catch((err) => {
                        console.error("Error at image upload!");
                        console.error(err);
                        Swal.fire({
                            title: "Gagal melakukan upload bukti pembayaran!",
                            text: "Silahkan mencoba beberapa saat lagi atau coba ulang dengan ukuran gambar yang lebih kecil.",
                            icon: "error",
                        });
                    });
            },
        });
    };
    const handleTicketChange = (evt: any) => {
        setSelectedTicket(evt.target.value);
    };
    const getCurrentItem = () => {
        const item = items.find((o) => o.id === selectedTicket);
        return item;
    };
    const isQuotaExists = () => {
        const item = getCurrentItem();
        return item ? item.left_qty > 0 : false;
    };
    const handleTotalGuardianChange = (e: any) => {
        const total = e.target.value;
        const add = total - formik.values.guardians.length;
        if (add > 0) {
            for (let i = 0; i < add; i++) {
                formik.values.guardians.push({
                    name: "",
                    phone_number: "",
                } as Guardian);
            }
        } else if (add < 0) {
            for (let i = 0; i < add * -1; i++) {
                formik.values.guardians.pop();
            }
        }
        formik.handleChange(e);
    };
    const renderTicketQR = (regDetail?: RegistrationDetail) => {
        if (regDetail == null) {
            return <></>;
        }
        return (
            <div className="card">
                <div className="card-body">
                    <div className="text-center qrcode-wrapper">
                        <div className="mb-2">
                            QR Code re-Registrasi di hari H
                        </div>
                        <QRCode
                            value={regDetail.ticket_value!}
                            size={128}
                        ></QRCode>
                    </div>
                </div>
            </div>
        );
    };
    const renderRegistrationForm = () => {
        return (
            <>
                {!isLoading &&
                    isNotRegistered(registrant.registration_status) && (
                        <Disclaimer asAgreement={true} />
                    )}
                {/* page registrasi */}
                <div className="app-content content">
                    <h1 className="text-center mb-4">
                        Registrasi Senior Retreat GMS 2 Surabaya Barat
                    </h1>
                    <div className="content-wrapper">
                        {isRegistered(registrant.registration_status) &&
                            renderTicketQR(
                                registrant.registration_status
                                    ?.registration_detail
                            )}
                        <Form noValidate onSubmit={formik.handleSubmit}>
                            {/* Profile */}
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label>Nama</label>
                                        <div>{registrant.name}</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Tanggal Lahir</label>
                                        <div>{registrant.birth_date}</div>
                                    </div>
                                    <div className="form-group">
                                        <label>No. HP (WA)</label>
                                        <div>{registrant.cell_phone}</div>
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <div>{registrant.email}</div>
                                    </div>
                                    <Form.Group
                                        controlId={`validationFormikAddress`}
                                        className="form-group"
                                        hidden={
                                            !isNotRegistered(
                                                registrant.registration_status
                                            )
                                        }
                                    >
                                        <Form.Label>
                                            Alamat
                                            <span
                                                className="vnumgf"
                                                aria-label="Required question"
                                            >
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name={`address`}
                                            value={formik.values["address"]}
                                            isInvalid={!!formik.errors.address}
                                            onChange={formik.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.address}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Lokasi Retreat</Form.Label>
                                        <div className="mb-3">
                                            <a href="https://goo.gl/maps/SbDqSq8tm7dr5pMC6?coh=178571&entry=tt&target=_blank">
                                                Rooftop - Pakuwon Mall Perumahan
                                                Pakuwon Indah Jl. Puncak Indah
                                                Lontar 2
                                            </a>
                                        </div>
                                    </Form.Group>
                                    {isNotRegistered(
                                        registrant.registration_status
                                    ) && (
                                        <>
                                            <div className="form-group">
                                                <label>Tiket</label>
                                                <div>
                                                    <select
                                                        value={selectedTicket}
                                                        className="form-control"
                                                        onChange={
                                                            handleTicketChange
                                                        }
                                                    >
                                                        <option value="">
                                                            -- Pilih Tiket --
                                                        </option>
                                                        {items.map((item) => {
                                                            return (
                                                                <option
                                                                    key={
                                                                        "option-item-" +
                                                                        item.id
                                                                    }
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <Quota
                                                item={getCurrentItem()}
                                                alwaysShow={false}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* Form Guardian */}
                            {isNotRegistered(registrant.registration_status) &&
                                renderGuardianCard()}
                            {/* Detail Registrasi */}
                            {registrant.registration_status?.registered && (
                                <RegistrationStatusWidget
                                    registrationStatus={
                                        registrant.registration_status!
                                    }
                                    imageUploadHandler={handleImageUpload}
                                />
                            )}
                            {isNotRegistered(
                                registrant.registration_status
                            ) && (
                                <div className="form-group">
                                    <button
                                        disabled={isLoading || !isQuotaExists()}
                                        className="btn btn-primary w-100"
                                    >
                                        Daftar
                                    </button>
                                </div>
                            )}
                            <div className="text-center mt-4">
                                Ada kendala atau pertanyaan?
                                <br />
                                Silahkan hubungi hotline GMS Surabaya Barat di:
                                <br />
                                <a
                                    href={`https://wa.me/${process.env.REACT_APP_CALL_CENTER}?target=_blank`}
                                >
                                    {process.env.REACT_APP_CALL_CENTER}
                                </a>{" "}
                                (chat only)
                            </div>
                        </Form>
                    </div>
                </div>
            </>
        );
    };
    const renderGuardianCard = () => {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="form-group">
                        <label>Jumlah Pendamping (bila ada)</label>
                        <Form.Select
                            value={formik.values.guardian_total}
                            name={`guardian_total`}
                            onChange={handleTotalGuardianChange}
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </Form.Select>
                    </div>
                    {formik.values.guardians?.map((value: any, i: number) => {
                        return renderGuardianForm(value, i);
                    })}
                </div>
            </div>
        );
    };
    const renderGuardianForm = (value: any, i: number) => {
        const number = i + 1;
        const key = `guardian-form-${i}`;
        var error = {
            name: "",
            phone_number: "",
        } as Guardian;
        if (formik.errors.guardians !== undefined) {
            error = formik.errors.guardians[i] as Guardian;
        }
        return (
            <div key={key}>
                <Form.Group className="mb-3">
                    <Form.Label>
                        Nama Lengkap Pendamping {number} (Sesuai KTP)
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Nama Pendamping"
                        value={value.name}
                        style={{ textTransform: "uppercase" }}
                        name={`guardians[${i}]name`}
                        isInvalid={error !== undefined ? !!error.name : false}
                        onChange={formik.handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        {error?.name}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>No HP Pendamping {number}</Form.Label>
                    <Form.Control
                        type="tel"
                        placeholder="Nomor Telepon Pendamping"
                        value={value.phone_number}
                        isInvalid={
                            error !== undefined ? !!error.phone_number : false
                        }
                        name={`guardians[${i}]phone_number`}
                        onChange={formik.handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        {error?.phone_number}
                    </Form.Control.Feedback>
                </Form.Group>
            </div>
        );
    };
    return <div id="mainRegister">{renderRegistrationForm()}</div>;
}

export default RegisterPage;
