import React from "react";
import { Item } from "../../interfaces/interfaces";

type Props = {
  item?: Item;
  alwaysShow?: boolean;
};

export const isQuotaExists = (item?: Item, total: number = 1) => {
  return item ? item.left_qty >= total : false;
};

export default function Quota(props: Props) {
  const showQuota = () => {
    const item = props.item;
    if (item) {
      if (props.alwaysShow === undefined || props.alwaysShow === true) {
        return true;
      } else {
        const totalQty = item.total_qty;
        const leftQty = item.left_qty;
        const percent = (leftQty / totalQty) * 100;
        return percent <= 30;
      }
    }
    return false;
  };
  let label = <></>;
  if (props.item) {
    label =
      props.item.left_qty > 0 ? (
        <span>{props.item.left_qty}</span>
      ) : (
        <span className="badge badge-danger">Quota telah habis</span>
      );
  }
  return (
    <div hidden={!showQuota()} className="form-group">
      <label>Quota tersisa</label>
      <div className="form-control-static">
        <b style={{ fontSize: "1.2em" }}>{label}</b>
      </div>
    </div>
  );
}
