import React from "react";
import { Transaction } from "../../interfaces/interfaces";

type Props = {
    transaction: Transaction;
    imageUploadHandler: any;
}

export default function BcaHowTo(props: Props) {
    const transaction = props.transaction;
    return (<div className="va-wrapper">
        <div>Silahkan melakukan pembayaran melalui:</div>
        <br />
        <div>BCA Virtual Account</div>
        <div>GRJ MAWARSHARON</div>
        <div>
            <span className="va-number">{process.env.REACT_APP_BCA_VA_NUMBER}</span>
        </div>
        <div className="mt-4">
            {/* munculkan gambar bila sudah pernah ada */}
            {transaction.payment_receipt_url && (
                <div className="mb-2">
                    <img
                        src={transaction.payment_receipt_url}
                        alt="receipt"
                        className="w-100"
                    />
                </div>
            )}
            {/* tombol upload hanya muncul bila gambar belum pernah di upload */}
            {!transaction.payment_receipt_url && (
                <div>
                    Upload bukti pembayaran Anda
                    <input
                        type="file"
                        onChange={props.imageUploadHandler}
                        accept="image/*"
                    />
                </div>
            )}
            <div></div>
        </div>
    </div>
    )
}