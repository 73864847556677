/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { RegistrationStatus } from "../RegisterPage";
import BcaHowTo from "./BcaHowTo";
import CashHowTo from "./CashHowTo";
import Disclaimer from "./Disclaimer";

type Props = {
    registrationStatus: RegistrationStatus;
    imageUploadHandler: any;
};

// Labeling status pembayaran
export const getBadgeType = (status: string) => {
    switch (status) {
        case "EXPIRED":
        case "CANCELED":
            return "bg-danger";
        case "WAITING":
            return "bg-warning";
        case "PAID":
            return "bg-success";
        default:
            return "bg-secondary";
    }
};

const renderOrderedTicket = (registrationStatus: RegistrationStatus) => {
    var transaction = registrationStatus.transaction;
    var detail = registrationStatus.registration_detail;
    return (
        <>
            <div className="form-group">
                <label>
                    Tiket yang dipesan
                    {detail.with_guardian && (
                        <span className="badge badge-info ml-1">
                            dengan pendamping
                        </span>
                    )}
                </label>
                <div className="form-control-static">
                    {transaction.items[0].name}
                </div>
            </div>
            {detail.with_guardian && (
                <>
                    <div className="form-group">
                        <label>Informasi Pendamping</label>
                        <div className="form-control-static">
                            {detail.guardians.map((o, i) => {
                                return (
                                    <div>
                                        {i + 1}. {o.name} - {o.phone_number}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default function RegistrationStatusWidget(props: Props) {
    const [showConfirm, setShowConfirm] = useState(false);
    const transaction = props.registrationStatus.transaction;
    let label =
        transaction.payment_channel_id === 5
            ? "Ganti metode pembayaran"
            : "Lanjutkan pembayaran";
    return (
        <>
            {/* modal konfirmasi pendaftaran */}
            <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
                <Modal.Body>
                    <Disclaimer />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirm(false)}
                    >
                        Tutup
                    </Button>
                </Modal.Footer>
            </Modal>
            <div>
                {(transaction.status === "EXPIRED" ||
                    transaction.status === "CANCELED") && (
                    <div>
                        <div className="alert alert-danger">
                            {transaction.status === "CANCELED" &&
                                "Pendaftaran Anda telah dibatalkan. Segera hubungi hotline kami jika Anda tidak merasa melakukan pembatalan."}
                            {transaction.status === "EXPIRED" &&
                                "Pendaftaran Anda telah melewati batas waktu. Jika Anda telah melakukan pembayaran tetapi belum di verifikasi, silahkan hubungi hotline dengan menyertakan bukti pembayarandan bill number Anda."}
                        </div>
                    </div>
                )}
                <div className="card">
                    <div className="card-body">
                        <div className="form-group">
                            <label>Tanggal Registrasi</label>
                            <div className="form-control-static">
                                {moment(transaction.bill_at).format(
                                    "D MMM YYYY HH:mm"
                                )}
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Bill No.</label>
                            <div className="form-control-static">
                                {transaction.bill_no}
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Total Bayar</label>
                            <div className="form-control-static">
                                <b style={{ fontSize: "1.2em" }}>
                                    {new Intl.NumberFormat("id").format(
                                        transaction.bill_total
                                    )}
                                </b>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Status</label>
                            <div className="form-control-static">
                                <span
                                    className={
                                        "badge " +
                                        getBadgeType(transaction.status)
                                    }
                                >
                                    {transaction.status}
                                </span>
                            </div>
                        </div>
                        {renderOrderedTicket(props.registrationStatus)}
                        {transaction.status === "WAITING" && (
                            <div>
                                <div className="form-group">
                                    <label>Batas waktu pembayaran</label>
                                    <div className="form-control-static">
                                        <b>
                                            {moment(
                                                transaction.expired_at
                                            ).format("D MMM YYYY HH:mm")}
                                        </b>
                                    </div>
                                </div>
                                {transaction.payment_channel_id === 5 && (
                                    <BcaHowTo
                                        imageUploadHandler={
                                            props.imageUploadHandler
                                        }
                                        transaction={transaction}
                                    />
                                )}
                                {transaction.payment_channel_id === 6 && (
                                    <CashHowTo
                                        bill_no={transaction.bill_no}
                                        payment_qr={transaction.payment_qr}
                                    />
                                )}
                                <div className="mt-4">
                                    <button
                                        className="btn btn-link p-0"
                                        onClick={() => setShowConfirm(true)}
                                    >
                                        Lihat Disclaimer
                                    </button>
                                    <br />
                                    <br />
                                    <a
                                        href={transaction.payment_url}
                                        className="btn btn-primary w-100"
                                    >
                                        {label}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
